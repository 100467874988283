
import { defineComponent, PropType } from 'vue';
import { GridElement } from '@/models/GridElement';

export default defineComponent({
  components: {},
  props: {
    gridElements: {
      type: Array as PropType<GridElement[]>,
      required: true
    }
  }
});
