
import { defineComponent, PropType, ref } from 'vue';
import { FilterOption } from './../models/FilterOptions';
import IconComponent from './icon/IconComponent.vue';
import GridComponent from '@/components/GridComponent.vue';
import { GridElement } from '../models/GridElement';

export default defineComponent({
  components: { IconComponent, GridComponent },
  props: {
    options: {
      type: Object as PropType<FilterOption<unknown>[]>,
      default: () => ({})
    },
    gridData: {
      type: Array as PropType<GridElement[]>,
      default: () => []
    }
  },
  emits: ['filterSelected'],
  setup(props, { emit }) {
    const optionsRef = ref(props.options);
    const activeValue = ref(null as unknown | null);
    const handleFilterClick = (filterOption: FilterOption<unknown>) => {
      if (filterOption.value === activeValue.value) {
        activeValue.value = null;
      } else {
        activeValue.value = filterOption.value;
      }
      emit('filterSelected', activeValue.value);
    };

    return {
      handleFilterClick,
      optionsRef,
      activeValue
    };
  }
});
