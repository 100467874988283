import { ElkNode } from 'elkjs';

interface LearningObjective {
  title: string;
}

interface Task {
  title: string;
  tasks: Task[];
  learningObjectives: LearningObjective[];
}

export enum LearningObjectiveStatus {
  Unknown = 0,
  NotStarted = 1 << 0,
  Satisfactory = 1 << 1,
  NeedsImprovement = 1 << 2,
  Critical = 1 << 3,
  CARDINALITY
}

export const LearningObjectiveStatusStringLabels = {
  [LearningObjectiveStatus.Unknown]: 'Unknown',
  [LearningObjectiveStatus.NotStarted]: 'Not Started',
  [LearningObjectiveStatus.Satisfactory]: 'Satisfactory',
  [LearningObjectiveStatus.NeedsImprovement]: 'Needs Improvement',
  [LearningObjectiveStatus.Critical]: 'Critical'
};

export const LearningObjectiveStatusStringValues = {
  [LearningObjectiveStatus.Unknown]: 'unknown',
  [LearningObjectiveStatus.NotStarted]: 'not-started',
  [LearningObjectiveStatus.Satisfactory]: 'satisfactory',
  [LearningObjectiveStatus.NeedsImprovement]: 'needs-improvement',
  [LearningObjectiveStatus.Critical]: 'critical'
};

export const UNKNOWN = 'unknown.svg';
export const FULL_RED_CRITICAL = 'full_red_critical.svg';
export const HALF_RED_CHECK = 'half_red_check.svg';
export const BLUE_CHECK = 'blue_check.svg';
export const EMPTY = 'empty.svg';

export const LearningObjectiveStatusBadges: Map<
  LearningObjectiveStatus,
  string
> = new Map([
  [LearningObjectiveStatus.Unknown, UNKNOWN],
  [LearningObjectiveStatus.NotStarted, EMPTY],
  [LearningObjectiveStatus.Satisfactory, BLUE_CHECK],
  [LearningObjectiveStatus.NeedsImprovement, HALF_RED_CHECK],
  [LearningObjectiveStatus.Critical, FULL_RED_CRITICAL]
]);

export function getRandomLearningObjectiveStatus(): LearningObjectiveStatus {
  const maxShift = Math.log2(LearningObjectiveStatus.CARDINALITY - 1) + 1;
  const statusShiftCount = Math.floor(maxShift * Math.random());
  return 1 << statusShiftCount;
  // return LearningObjectiveStatus.Critical;
}

export function getBadgeForLearningObjectiveStatus(
  status: LearningObjectiveStatus
): string {
  return LearningObjectiveStatusBadges.get(status) || UNKNOWN;
}

export interface KsaoNode extends ElkNode {
  type: string;
  title: string;
  children: KsaoNode[];
}
export { Task, LearningObjective };
