
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String as PropType<string>,
      default: 'Missing Title'
    },
    progress: {
      type: Number as PropType<number>,
      default: 0
    },
    active: {
      type: Boolean as PropType<boolean>,
      default: false
    }
  },
  emits: ['filterSelected'],
  setup(props, { emit }) {
    const progressArray = Array.from({ length: 10 }, () => ({
      complete: false
    })).map((_, i) => ({
      complete: i < props.progress
    }));
    return { progressArray };
  }
});
