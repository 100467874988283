// https://ourcade.co/tools/phaser3-text-styler/
import 'phaser';
import '@/assets/styles/style.css';
import {
  getBadgeForLearningObjectiveStatus,
  getRandomLearningObjectiveStatus,
  LearningObjectiveStatus,
  LearningObjectiveStatusBadges
} from './TaskCard';
import { ElkExtendedEdge, ElkNode } from 'elkjs';
import {
  LEARNING_OBJECTIVE,
  LEARNING_OBJECTIVE_CONTAINER,
  TASK
} from '../constants';
import Elkjs from '../elkjs-phaser/Elkjs';
import { colors } from '../constants';
import { GridElement } from '../../../models/GridElement';

export default class Scene extends Phaser.Scene {
  layout!: ElkNode;
  elkjs: Elkjs = new Elkjs();
  debug = false;
  dirty = true;
  graphics!: Phaser.GameObjects.Graphics;
  filterStatusPath: Set<string> = new Set();
  statusFilterState: LearningObjectiveStatus | null = null;
  statusFilter: {
    value: LearningObjectiveStatus | null;
    fetchFilterValue: () => LearningObjectiveStatus | null;
  };
  onInit: () => void;

  constructor(
    statusFilter: {
      value: LearningObjectiveStatus | null;
      fetchFilterValue: () => LearningObjectiveStatus | null;
    },
    onInit: () => void
  ) {
    super({
      key: 'ElkExamplesScene'
    });
    this.statusFilter = statusFilter;
    this.statusFilterState = statusFilter.value;
    this.onInit = onInit;
  }

  async init(/*params: any*/): Promise<void> {
    this.layout = await this.elkjs.layout(
      this.sys.game.canvas.width,
      this.sys.game.canvas.height
    );

    /*
      Randomly assign status to all leaf nodes
      Calculate aggregate status for all predecessors of all leaf nodes
      Does not calculate aggregate status for any nodes tnat do not have children
    */
    const leafNodes = this.elkjs.cy
      .nodes()
      .filter((node) => node.data('label') === LEARNING_OBJECTIVE);

    leafNodes.forEach((node) => {
      const status = getRandomLearningObjectiveStatus();
      node.data('norman_was_here_learning_objective_status', status);
    });

    leafNodes.forEach((node) => {
      function collectSuccessorStatusBadges(node: cytoscape.NodeSingular) {
        const successors = node
          .successors()
          .filter(
            (successor) =>
              successor.isNode() &&
              successor.data('label') !== LEARNING_OBJECTIVE_CONTAINER
          );
        return successors.reduce((acc, successor) => {
          const status: LearningObjectiveStatus = successor.data(
            'norman_was_here_learning_objective_status'
          );
          if (status) {
            acc = acc |= status;
          }
          return acc;
        }, LearningObjectiveStatus.Unknown);
      }
      const predecessors = node.predecessors();
      const targets: cytoscape.NodeSingular[] = [];
      predecessors.forEach((pred) => {
        const target = pred;
        if (
          target.isNode() &&
          target.data('label') !== LEARNING_OBJECTIVE_CONTAINER
        ) {
          targets.push(pred);
        }
      });
      targets.forEach((target) => {
        const status = collectSuccessorStatusBadges(target);
        target.data('norman_was_here_learning_objective_status', status);
      });
    });
    // END RANDOM ASSIGNMENT
    this.onInit();
  }

  formGridData(): GridElement[] {
    const satisfactoryArray: GridElement[] = [];
    const criticalArray: GridElement[] = [];
    const needsImprovementArray: GridElement[] = [];
    const notStartedArray: GridElement[] = [];

    this.elkjs.cy
      .nodes()
      .filter((node) => node.data('label') === LEARNING_OBJECTIVE)
      .forEach((node) => {
        switch (node.data('norman_was_here_learning_objective_status')) {
          case LearningObjectiveStatus.Satisfactory.valueOf():
            satisfactoryArray.push({
              id: node.data('id'),
              label: node.data('title'),
              color: '#0073FF'
            });
            break;
          case LearningObjectiveStatus.NeedsImprovement.valueOf():
            needsImprovementArray.push({
              id: node.data('id'),
              label: node.data('title'),
              color: '#F18B36'
            });
            break;
          case LearningObjectiveStatus.Critical.valueOf():
            criticalArray.push({
              id: node.data('id'),
              label: node.data('title'),
              color: '#FF8181'
            });
            break;
          case LearningObjectiveStatus.NotStarted.valueOf():
            notStartedArray.push({
              id: node.data('id'),
              label: node.data('title'),
              color: '#7F7F93'
            });
            break;
          default:
            break;
        }
      });

    return [
      ...satisfactoryArray,
      ...criticalArray,
      ...needsImprovementArray,
      ...notStartedArray
    ];
  }

  preload(): void {
    const svgResolution = { width: 64, height: 64 };
    this.load.css('80s', '/style.css');
    LearningObjectiveStatusBadges.forEach((value) => {
      this.load.svg(value, `/${value}`, svgResolution);
    });
    this.load.image('fire', '/muzzleflash3.png');
  }

  drawArrow(
    graphics: Phaser.GameObjects.Graphics,
    cx: number,
    cy: number,
    outerRadius: number,
    color: number,
    lineColor: number
  ): void {
    const x = cx;
    const y = cy;

    graphics.lineStyle(1, lineColor, 1);
    graphics.fillStyle(color, 1);
    graphics.beginPath();
    graphics.moveTo(cx, cy);

    graphics.lineTo(x - outerRadius / 2, y - outerRadius / 2);
    graphics.lineTo(x - outerRadius / 2, y + outerRadius / 2);

    graphics.lineTo(cx, cy);
    graphics.closePath();
    graphics.fillPath();
    graphics.strokePath();
  }

  draw(): void {
    const elkjs = this.elkjs;
    const graphics = this.graphics;
    const filterStatusPath: Set<string> = this.filterStatusPath;
    const statusFilterState: LearningObjectiveStatus | null = this
      .statusFilterState;

    function drawChild(
      child: ElkNode,
      factory: Phaser.GameObjects.GameObjectFactory,
      offset: Phaser.Math.Vector2,
      filterStatusPath: Set<string>,
      statusFilterState: LearningObjectiveStatus | null,
      where: (node: ElkNode) => boolean,
      debug = false
    ) {
      if (child.children && child.children.length) {
        const parent = child;
        const parentPosition = new Phaser.Math.Vector2(parent.x, parent.y);
        child.children?.forEach((child) => {
          drawChild(
            child,
            factory,
            parentPosition,
            filterStatusPath,
            statusFilterState,
            where,
            debug
          );
        });
      }

      const position = { x: child.x || 0, y: child.y || 0 };
      let dimensions: { width: number; height: number };
      if (child.width && child.height) {
        dimensions = { width: child.width, height: child.height };
      } else {
        dimensions = { width: 0, height: 0 };
      }

      const textOffset = new Phaser.Math.Vector2(offset.x, offset.y);

      const data = {
        type: elkjs.cy.$(`#${child.id}`).data('label'),
        status: elkjs.cy
          .$(`#${child.id}`)
          .data('norman_was_here_learning_objective_status'),
        id: elkjs.cy.$(`#${child.id}`).data('id'),
        title: elkjs.cy.$(`#${child.id}`).data('title'),
        norman_was_here_learning_objective_status: elkjs.cy
          .$(`#${child.id}`)
          .data('norman_was_here_learning_objective_status')
      };

      if (!where(child)) {
        return;
      }

      const filterSsInStatusPath = filterStatusPath.has(data.id);

      const getNodeStatusColor = (
        statusFilterState: LearningObjectiveStatus | null
      ) => {
        switch (statusFilterState) {
          case LearningObjectiveStatus.Satisfactory:
            return colors.satisfactory;
          case LearningObjectiveStatus.NeedsImprovement:
            return colors.needsImprovement;
          case LearningObjectiveStatus.Critical:
            return colors.critical;
          case LearningObjectiveStatus.NotStarted:
            return colors.notStarted;
          default:
            return 0x1a1a1a;
        }
      };

      if (data.type === LEARNING_OBJECTIVE) {
        let textColor;
        let textBackgroundColor;
        if (filterSsInStatusPath && statusFilterState !== null) {
          textBackgroundColor = colors.cssExtralightGray;
          textColor = '#ffffff';
        } else if (!filterSsInStatusPath && statusFilterState !== null) {
          textBackgroundColor = colors.cssDarkGray;
          textColor = '#6f7179';
        } else {
          textBackgroundColor = colors.cssLightGray;
          textColor = '#ffffff';
        }

        const style: Phaser.Types.GameObjects.Text.TextStyle = {
          fontFamily: 'Quicksand',
          fontSize: '16px',
          color: textColor,
          backgroundColor: textBackgroundColor,
          resolution: 1.5
        };

        const text = factory.text(
          (child.x || 0) + textOffset.x + 10,
          (child.y || 0) + textOffset.y + 10,
          data.title,
          style
        );

        text.setOrigin(0.0, 0.5);

        const badge = factory.image(
          text.x,
          text.y,
          getBadgeForLearningObjectiveStatus(
            data.norman_was_here_learning_objective_status
          )
        );
        badge.displayWidth = 15;
        badge.displayHeight = 15;
        badge.setDepth(1);

        text.setPosition(text.x + badge.displayWidth, text.y);

        if (filterSsInStatusPath) {
          const padding = 10;
          const paddingOffset = padding / 2;
          graphics.lineStyle(4, getNodeStatusColor(statusFilterState), 1);
          graphics.strokeRoundedRect(
            text.x - badge.displayWidth * 2,
            text.y - text.displayHeight / 2 - paddingOffset,
            text.displayWidth + badge.displayWidth * 2 + padding,
            text.displayHeight + padding,
            5
          );
          graphics.fillStyle(colors.extralightGray, 1);
          graphics.fillRoundedRect(
            text.x - badge.displayWidth * 2,
            text.y - text.displayHeight / 2 - paddingOffset,
            text.displayWidth + badge.displayWidth * 2 + padding,
            text.displayHeight + padding,
            5
          );
        }
      }
      if (data.type === TASK) {
        const color = filterSsInStatusPath
          ? getNodeStatusColor(statusFilterState)
          : colors.extraDarkGray;

        // Border Box
        graphics.fillStyle(color, 1);
        graphics.fillRoundedRect(
          position.x + textOffset.x - 2,
          position.y + textOffset.y - 2,
          dimensions.width + 4,
          dimensions.height + 4,
          8 // px radius of corner
        );
        // Background Box
        graphics.fillStyle(colors.lightGray, 1);
        graphics.fillRoundedRect(
          position.x + textOffset.x,
          position.y + textOffset.y,
          dimensions.width,
          dimensions.height,
          8 // px radius of corner
        );

        const style: Phaser.Types.GameObjects.Text.TextStyle = {
          fontFamily: 'Quicksand',
          fontSize: '16px',
          color: '#fff',
          backgroundColor: colors.cssLightGray,
          maxLines: 2,
          wordWrap: { width: dimensions.width - 15 },
          align: 'center',
          resolution: 2
        };
        const text = factory.text(
          (child.x || 0) + textOffset.x + (child.width || 0) / 2,
          (child.y || 0) + textOffset.y + (child.height || 0) / 2,
          data.title,
          style
        );
        text.setOrigin(0.5);
        // statuses.map((status, index) => {
        //   const localDimensions = { width: 25, height: 25 };
        //   const centeredOffset =
        //     localDimensions.width * Math.floor(statuses.length / 2);
        //   const badge = getBadgeForLearningObjectiveStatus(status);
        //   const image = factory.image(
        //     position.x -
        //       centeredOffset +
        //       localDimensions.width * index +
        //       dimensions.width / 2,
        //     position.y + dimensions.height / 6,
        //     badge
        //   );
        //   image.displayWidth = localDimensions.width;
        //   image.displayHeight = localDimensions.height;
        //   return badge;
        // });
        if (debug) {
          graphics.lineStyle(1, 0xffffff, 1);
          graphics.strokeRectShape(text.getBounds());
        }
      }
      if (data.type === LEARNING_OBJECTIVE_CONTAINER) {
        const drawHeadingLabel = false;
        const backgroundColor =
          statusFilterState !== null ? colors.darkGray : colors.lightGray;
        graphics.fillStyle(backgroundColor, 1);
        graphics.fillRoundedRect(
          position.x + textOffset.x,
          position.y + textOffset.y,
          dimensions.width,
          dimensions.height,
          8 // px radius of corner
        );

        if (drawHeadingLabel) {
          const style: Phaser.Types.GameObjects.Text.TextStyle = {
            fontFamily: 'Quicksand',
            fontSize: '16px',
            color: '#fff',
            maxLines: 2,
            wordWrap: { width: child.width },
            align: 'center'
          };
          const text = factory.text(
            child.x || 0,
            child.y || 0,
            data.title,
            style
          );
          text.setOrigin(0, 1);
          if (debug) {
            graphics.lineStyle(1, 0xffffff, 1);
            graphics.strokeRectShape(text.getBounds());
          }
        }
      }
    }

    function drawChildren(
      node: ElkNode,
      factory: Phaser.GameObjects.GameObjectFactory,
      filterStatusPath: Set<string>,
      where: (node: ElkNode) => boolean
    ) {
      node.children?.forEach((child) => {
        drawChild(
          child,
          factory,
          new Phaser.Math.Vector2(0, 0),
          filterStatusPath,
          statusFilterState,
          where
        );
      });
    }

    const factory = this.add;

    // Draw the learning objective containers first
    drawChildren(this.layout, factory, filterStatusPath, (node: ElkNode) => {
      const data = {
        type: elkjs.cy.$(`#${node.id}`).data('label')
      };
      return data.type === LEARNING_OBJECTIVE_CONTAINER;
    });
    // Draw everything else after so its painted above the learning objective containers
    drawChildren(this.layout, factory, filterStatusPath, (node: ElkNode) => {
      const data = {
        type: elkjs.cy.$(`#${node.id}`).data('label')
      };
      return data.type !== LEARNING_OBJECTIVE_CONTAINER;
    });

    const getEdgeColor = (
      statusFilterState: LearningObjectiveStatus | null,
      filterSsInStatusPath: boolean
    ) => {
      if (filterSsInStatusPath) {
        switch (statusFilterState) {
          case LearningObjectiveStatus.Satisfactory:
            return colors.satisfactory;
          case LearningObjectiveStatus.NeedsImprovement:
            return colors.needsImprovement;
          case LearningObjectiveStatus.Critical:
            return colors.critical;
          case LearningObjectiveStatus.NotStarted:
            return colors.notStarted;
          default:
            return 0x1a1a1a;
        }
      } else {
        return 0x5c5c5c;
      }
    };

    // draw each edge not in status filter path
    this.layout.edges?.forEach((edge) => {
      const e = edge as ElkExtendedEdge;
      const filterSsInStatusPath = filterStatusPath.has(e.id);
      const color = getEdgeColor(this.statusFilterState, filterSsInStatusPath);
      graphics.lineStyle(2, color, 1);
      if (!filterSsInStatusPath) {
        if (this.debug) {
          e.junctionPoints?.forEach((point) => {
            factory.circle(point.x + 3.5, point.y - 3.5, 7, 0xff0000);
          });
        }
        e.sections?.forEach((section) => {
          if (this.debug) {
            section.bendPoints?.forEach((point) => {
              factory.circle(point.x, point.y, 5, 0x00ff00);
            });
          }
          const bendPoints = section.bendPoints?.map((bendPoint) => {
            return new Phaser.Math.Vector2(bendPoint.x, bendPoint.y);
          });
          if (bendPoints) {
            const path = new Phaser.Curves.Path(
              section.startPoint.x,
              section.startPoint.y
            );
            bendPoints.forEach((point) => path.lineTo(point.x, point.y));
            path.lineTo(section.endPoint.x, section.endPoint.y);
            path.draw(graphics, 64);
          } else {
            const path = new Phaser.Curves.Path(
              section.startPoint.x,
              section.startPoint.y
            );
            path.lineTo(section.endPoint.x, section.endPoint.y);
            path.draw(graphics, 64);
          }

          if (this.debug) {
            factory.circle(
              section.startPoint.x,
              section.startPoint.y,
              5,
              0xffffff
            );
            factory.circle(section.endPoint.x, section.endPoint.y, 5, 0x0000ff);
          }
          this.drawArrow(
            graphics,
            section.endPoint.x,
            section.endPoint.y,
            15,
            colors.lightGray,
            colors.lightGray
          );
        });
      }
    });

    // draw each edge in status filter path
    // this lets the edges which are in the status path be drawn on top of the edges which are not
    this.layout.edges?.forEach((edge) => {
      const e = edge as ElkExtendedEdge;
      const filterSsInStatusPath = filterStatusPath.has(e.id);
      const color = getEdgeColor(this.statusFilterState, filterSsInStatusPath);
      graphics.lineStyle(4, color, 1);
      if (filterSsInStatusPath) {
        if (this.debug) {
          e.junctionPoints?.forEach((point) => {
            factory.circle(point.x, point.y, 7, 0xff0000);
          });
        }
        e.sections?.forEach((section) => {
          if (this.debug) {
            section.bendPoints?.forEach((point) => {
              factory.circle(point.x, point.y, 5, 0x00ff00);
            });
          }
          const bendPoints = section.bendPoints?.map((bendPoint) => {
            return new Phaser.Math.Vector2(bendPoint.x, bendPoint.y);
          });
          if (bendPoints) {
            const path = new Phaser.Curves.Path(
              section.startPoint.x,
              section.startPoint.y
            );
            bendPoints.forEach((point) => path.lineTo(point.x, point.y));
            path.lineTo(section.endPoint.x, section.endPoint.y);
            path.draw(graphics, 64);
          } else {
            const path = new Phaser.Curves.Path(
              section.startPoint.x,
              section.startPoint.y
            );
            path.lineTo(section.endPoint.x, section.endPoint.y);
            path.draw(graphics, 64);
          }

          if (this.debug) {
            factory.circle(
              section.startPoint.x,
              section.startPoint.y,
              5,
              0xffffff
            );
            factory.circle(section.endPoint.x, section.endPoint.y, 5, 0x0000ff);
          }
        });
      }
    });
  }

  async create(): Promise<void> {
    this.graphics = this.add.graphics();
    const cam = this.cameras.main;

    const handlePointerMove = (p: Phaser.Input.Pointer) => {
      if (!p.isDown) {
        return;
      }

      cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
      cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
    };
    this.input.on('pointermove', handlePointerMove);

    this.cameras.main.setZoom(0.75);

    this.input.on(
      'wheel',
      (
        pointer: Phaser.Input.Pointer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        gameObjects: any,
        deltaX: number,
        deltaY: number,
        deltaZ: number
      ) => {
        const config = { pointer, gameObjects, deltaX, deltaY, deltaZ };
        let direction = 1;
        if (config.deltaY < 0) {
          direction = -1;
        }
        this.cameras.main.setZoom(this.cameras.main.zoom - direction * 0.015);
      }
    );
  }

  clear(): void {
    this.graphics?.clear();
  }

  update(/*time: number*/): void {
    if (this.statusFilterState !== this.statusFilter.fetchFilterValue()) {
      this.dirty = true;
      this.statusFilterState = this.statusFilter.fetchFilterValue();
      this.filterStatusPath = new Set();
    }
    if (this.dirty) {
      const filteredNodes = this.elkjs.cy
        .nodes()
        .filter((node) => {
          return node.data('label') === LEARNING_OBJECTIVE;
        })
        .filter((node) => {
          return (
            node.data('norman_was_here_learning_objective_status') ===
            this.statusFilterState
          );
        });
      filteredNodes.forEach((node) => {
        this.filterStatusPath.add(node.id());
        node.predecessors().forEach((predecessor) => {
          this.filterStatusPath.add(predecessor.id());
        });
      });

      this.clear();

      this.draw();
      this.dirty = false;
    }
  }
}
