
import { defineComponent, PropType, ref } from 'vue';
import { FilterOption } from './../models/FilterOptions';
import Icon from './icon/Icon';
import IconComponent from './icon/IconComponent.vue';

export default defineComponent({
  components: { IconComponent },
  props: {
    label: {
      type: String as PropType<string>,
      default: 'No recommendation available'
    },
    icon: {
      type: Object as PropType<Icon>,
      default: Icon.NONE
    }
  },
  emits: ['filterSelected'],
  setup(props) {
    return {};
  }
});
