import Icon from '../../components/icon/Icon';
import { FilterOption } from '../../models/FilterOptions';
import {
  LearningObjectiveStatus,
  LearningObjectiveStatusStringLabels
} from './scenes/TaskCard';

const CANVAS_DEFAULTS = { width: 1920, height: 1080 };
const CANVAS = { defaults: CANVAS_DEFAULTS };
const CARD = { width: 200, height: 100 };
const LEARNING_OBJECTIVE_LINE = {
  width: 500,
  height: 50
};
const lightGray = 0x484a54;
const extralightGray = 0x6f7179;
const darkGray = 0x4b4d57;
const extraDarkGray = 0x292c35;
const green = 0x00ff00;
const black = 0x000000;
const white = 0xffffff;
const satisfactory = 0x53b5ed;
const needsImprovement = 0xf18b36;
const critical = 0xff8181;
const notStarted = 0x7f7f93;
const whiteCss = '#ffffff';
const cssLightGray = '#484a54';
const cssExtralightGray = '#6f7179';
const cssDarkGray = '#4b4d57';
const cssExtraDarkGray = '#292C35';
const cssBlack = '#000000';
const cssSatisfactory = '#53b5ed';
const cssNeedsImprovement = '#F18B36';
const cssCritical = '#FF8181';
const cssNotStarted = '#7F7F93';

export const LEARNING_OBJECTIVE = 'LEARNING_OBJECTIVE';
export const TASK = 'TASK';
export const LEARNING_OBJECTIVE_CONTAINER = 'LEARNING_OBJECTIVE_CONTAINER';
export const colors = {
  lightGray,
  extralightGray,
  darkGray,
  extraDarkGray,
  green,
  black,
  white,
  satisfactory,
  needsImprovement,
  critical,
  notStarted,
  whiteCss,
  cssLightGray,
  cssExtralightGray,
  cssDarkGray,
  cssExtraDarkGray,
  cssBlack,
  cssSatisfactory,
  cssNeedsImprovement,
  cssCritical,
  cssNotStarted
};
export { CARD, LEARNING_OBJECTIVE_LINE, CANVAS };

export const statusFilterOptions: FilterOption<LearningObjectiveStatus>[] = [
  {
    label:
      LearningObjectiveStatusStringLabels[LearningObjectiveStatus.Satisfactory],
    value: LearningObjectiveStatus.Satisfactory,
    styles: {
      backgroundColor: 'bg-light-blue',
      borderColor: 'border-light-blue'
    },
    icon: Icon.BLUE_CHECK
  },
  {
    label:
      LearningObjectiveStatusStringLabels[
        LearningObjectiveStatus.NeedsImprovement
      ],
    value: LearningObjectiveStatus.NeedsImprovement,
    styles: {
      backgroundColor: 'bg-orange',
      borderColor: 'border-orange'
    },
    icon: Icon.HALF_RED_CHECK
  },
  {
    label:
      LearningObjectiveStatusStringLabels[LearningObjectiveStatus.Critical],
    value: LearningObjectiveStatus.Critical,
    styles: {
      backgroundColor: 'bg-red-400',
      borderColor: 'border-red-400'
    },
    icon: Icon.RED_CRITICAL
  },
  {
    label:
      LearningObjectiveStatusStringLabels[LearningObjectiveStatus.NotStarted],
    value: LearningObjectiveStatus.NotStarted,
    styles: {
      backgroundColor: 'bg-gray-200',
      borderColor: 'border-gray-400'
    },
    icon: Icon.EMPTY
  }
];
