import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center w-full pb-2 text-gray-400" }
const _hoisted_2 = { class: "flex flex-col justify-center w-full h-20 bg-divider" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "flex flex-col justify-center" }
const _hoisted_5 = { class: "flex flex-col justify-center w-full h-full" }
const _hoisted_6 = { class: "w-10/12 ml-4 text-sm text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_component = _resolveComponent("icon-component")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode(_component_icon_component, {
            icon: _ctx.icon,
            class: "w-6 h-6 ml-4"
          }, null, 8, ["icon"])
        ]),
        _createVNode("div", _hoisted_5, [
          _createVNode("div", _hoisted_6, _toDisplayString(_ctx.label), 1)
        ])
      ])
    ])
  ]))
}