import ELK, { ElkEdge, ElkLabel, ElkNode, LayoutOptions } from 'elkjs';
import cytoscape, { CytoscapeOptions, NodeSingular } from 'cytoscape';
import data from '../../../../cms-generated/cytoscapeDatabase.json';
import { KsaoNode } from '../scenes/TaskCard';
import {
  CARD,
  LEARNING_OBJECTIVE,
  LEARNING_OBJECTIVE_CONTAINER,
  LEARNING_OBJECTIVE_LINE,
  TASK
} from '../constants';

const elk = new ELK();

interface CytoscapeNode {
  data: {
    id: string;
    title: string;
    estimate: number;
    label: string;
  };
}

interface CytoscapeEdge {
  data: {
    id: string;
    source: string;
    target: string;
  };
}

const cytoscapeOptions: cytoscape.CytoscapeOptions = { ...data };

class Elkjs {
  data: CytoscapeOptions = data;
  cy = cytoscape(cytoscapeOptions);

  insert(
    node: NodeSingular,
    newNode: CytoscapeNode,
    newEdge: CytoscapeEdge
  ): void {
    this.cy.add({
      group: 'nodes',
      data: newNode.data
    });
    const edges = node.connectedEdges().filter((edge) => {
      return edge.target().data('label') === 'LEARNING_OBJECTIVE';
    });
    this.cy.remove(edges);
    edges.forEach((removed) => {
      this.cy.add({
        group: 'edges',
        data: {
          id: removed.id(),
          weight: 75,
          source: newNode.data.id,
          target: removed.target().id()
        }
      });
    });
    this.cy.add({ group: 'edges', data: newEdge.data });
  }
  layout(width = 1920, height = 1080): Promise<ElkNode> {
    const nodes = this.cy.nodes();
    nodes.forEach((node) => {
      if (node.data('label') === TASK) {
        const childrenForNode = node
          .connectedEdges()
          .targets()
          .filter(function whereTargetIsLearningObjective(node) {
            return node.data('label') === LEARNING_OBJECTIVE;
          });
        if (childrenForNode.length) {
          const newNode: CytoscapeNode = {
            data: {
              estimate: 1,
              id: `inserted_${node.id()}`,
              label: LEARNING_OBJECTIVE_CONTAINER,
              title: 'Learning Objectives'
            }
          };
          const newEdge: CytoscapeEdge = {
            data: {
              id: `CREATE (${node.id()})-[:DEPENDS_ON]->(${newNode.data.id})`,
              source: node.id(),
              target: newNode.data.id
            }
          };
          this.insert(node, newNode, newEdge);
        }
      }
    });

    const children: KsaoNode[] = this.cy
      .nodes()
      .map((node) => {
        const ksaoNode: KsaoNode = {
          id: node.id(),
          type: node.data('label'),
          title: node.data('title'),
          children: node
            .connectedEdges()
            .targets()
            .filter(function whereTargetIsLearningObjective(node) {
              return node.data('label') === LEARNING_OBJECTIVE;
            })
            .map((node) => {
              return {
                id: node.id(),
                type: node.data('label'),
                title: node.data('title'),
                width: 700,
                height: 20,
                children: []
              };
            })
        };
        if (ksaoNode.type === LEARNING_OBJECTIVE) {
          ksaoNode.width = LEARNING_OBJECTIVE_LINE.width;
          ksaoNode.height = LEARNING_OBJECTIVE_LINE.height;
        } else {
          ksaoNode.width = CARD.width;
          ksaoNode.height = CARD.height;
        }

        return ksaoNode;
      })
      .filter((node) => {
        return node.type !== 'LEARNING_OBJECTIVE';
      });
    const labels: ElkLabel[] = [];
    const edges: ElkEdge[] = this.cy.edges().map((edge) => {
      return {
        id: edge.id(),
        sources: [edge.source().id()],
        targets: [edge.target().id()]
      };
    });

    // https://www.eclipse.org/elk/reference/options.html
    const layoutOptions: LayoutOptions = {
      'elk.direction': 'RIGHT',
      // 'elk.direction': 'DOWN',
      'elk.algorithm': 'layered',
      'elk.spacing.nodeNode': '50',
      'elk.layered.spacing.nodeNodeBetweenLayers': '80',
      'elk.layered.spacing.edgeNode': '80',
      'elk.layered.spacing.edgeEdge': '20',
      'elk.layered.spacing.edgeEdgeBetweenLayers': '55',
      // 'elk.padding': '[top=0.0,left=5.0,bottom=10.0,right=15.0]',
      'elk.nodePlacement.strategy': 'NETWORK_SIMPLEX',
      // 'org.eclipse.elk.hierarchyHandling': 'INCLUDE_CHILDREN',
      // connecting edges will emit from one port instead of an edge port per edge
      'org.eclipse.elk.layered.mergeEdges': 'true'
      // 'org.eclipse.elk.layered.unnecessaryBendpoints': 'true'
      // 'elk.layered.unnecessaryBendpoints': 'true'
      // 'org.eclipse.elk.layered.nodePlacement.bk.edgeStraightening':
      //   'IMPROVE_STRAIGHTNESS'
      // 'elk.layered.mergeHierarchyEdges': 'true'
      // 'org.eclipse.elk.debugMode': 'true'
    };
    const graph: ElkNode = {
      id: 'root',
      children,
      labels,
      edges,
      layoutOptions,
      height,
      width
    };

    return elk.layout(graph, { layoutOptions });
  }
}
export default Elkjs;
