import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex my-2 font-light cursor-pointer" }
const _hoisted_2 = { class: "flex flex-col justify-center" }
const _hoisted_3 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_component = _resolveComponent("icon-component")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_icon_component, {
        icon: _ctx.dropdownIcon,
        class: "w-3 h-3 mr-2"
      }, null, 8, ["icon"])
    ]),
    _createVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}