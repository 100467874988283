
import { defineComponent, PropType, ref } from 'vue';
import Icon from './icon/Icon';
import IconComponent from './icon/IconComponent.vue';

export default defineComponent({
  components: { IconComponent },
  props: {
    label: {
      type: String as PropType<string>,
      default: 'teestts'
    }
  },
  emits: ['filterSelected'],
  setup(props) {
    const dropdownIcon = ref(Icon.CHEVRON_UP_LIGHT);
    return { dropdownIcon };
  }
});
